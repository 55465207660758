<template lang="pug">
k-form-container(style-type="kolcenter-modal-box")
  template(#body)
    m-campaign-agreement-view(:promoter-campaign="promoterCampaign")
  template(#footer)
    k-button(
      type="shadow-light"
      @click="hideView"
    ) 關閉
</template>

<script setup>
import { useInstance } from 'skid-composables'
import KEditorView from '@sharedComponents/common/k-editor-view.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import kFormContainer from '@sharedComponents/common/k-form-container.vue'
import mCampaignAgreementView from '@sharedComponents/promoter_campaign/common/m-campaign-agreement-view.vue'

const props = defineProps({
  promoterCampaign: { type: Object, required: true }
})
const vueInstance = useInstance()

function hideView() {
  vueInstance.$parent?.close()
}
</script>
