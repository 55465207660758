<template lang="pug">
.vc-shared-promoter-campaign-m-campaign-sales-time-range-view(
  :is="component"
  :promoter-campaign="promoterCampaign"
  :selected-campaign-period-ids="selectedCampaignPeriodIds"
  :display-type="displayType"
  :skip-label="skipLabel"
  :format="format"
)
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'skid-composables'
import moment from 'moment'
import isMainView from './m_campaign_sales_time_range_view/is-main-view.vue'
import isEventView from './m_campaign_sales_time_range_view/is-event-view.vue'

const props = defineProps({
  promoterCampaign: { type: Object, required: true },
  selectedCampaignPeriodIds: { type: Array },
  displayType: { type: String, required: false },
  skipLabel: { type: Boolean },
  format: {
    type: String,
    required: false,
    default() {
      return 'MM/DD (ddd)'
    }
  }
})
const store = useStore()

const component = computed(() => {
  switch (props.promoterCampaign.category) {
    case 'event':
      return isEventView
    default:
      return isMainView
  }
})

</script>
