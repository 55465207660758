export default {
  // components: {},

  props: {
    parent: {
      type: Object,
      required: true
    },

    parentStoreModuleName: {
      type: String,
      required: true
    },

    displayColumns: {
      type: Object,
      required: true,
      validator: (data) => {
        const validColumnKeys = ['square', 'horizontal', 'vertical']
        const isDimensionValid = (dimension) => {
          return (
            Array.isArray(dimension) &&
            dimension.length === 2 &&
            dimension.every((value) => Number(value) === value)
          )
        }

        Object.keys(data).forEach((key) => {
          if (!validColumnKeys.includes(key)) {
            throw new Error(
              `image column "${key}" must be one of the following: ` +
                validColumnKeys.join(', ')
            )
          }

          if (!isDimensionValid(data[key])) {
            throw new Error(
              `image column "${key}" must have an array dimension values eg. [100, 100]`
            )
          }
        })

        return true
      }
    }
  }

  // data() {
  //   return {}
  // },

  // computed: {},

  // methods: {}
}
