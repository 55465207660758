<template lang="pug">
.vc-shared-promoter-campaign-form-control-m-campaign-period-selection-input-by-date-time
  k-field(
    label="選擇活動日期"
  )
    .wrapper
      .unit(
        v-for=" (dateConfig, index) in eventSalesDates.dates"
        :key="index"
      )
        b-radio(
          type="is-info"
          name="date"
          v-model="data.selDate"
          @input="selectDateHandler"
          :native-value="moment(dateConfig.date).format(dateFormat)"
        )
          span {{ moment(dateConfig.date).format('YYYY-MM-DD (ddd)') }}
  k-field(
    v-if="data.selDate"
    label="選擇時段"
  )
    .wrapper
      k-checkbox(
        v-for="(timeConfig, index) in selectableTimes"
        :key="index"
        :value="value"
        :native-value="timeConfig.id"
        @input="inputHandler"
      )
        k-tag.is-light.mr-2(type="is-info") {{ timeConfig.name }}
        span {{ timeFor(timeConfig) }}
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, watch } from 'vue'
import { useStore, useI18n, useInstance } from 'skid-composables'
import KFieldGroup from '@sharedComponents/common/k-field-group.vue'
import KField from '@sharedComponents/common/k-field.vue'
import KInput from '@sharedComponents/common/k-input.vue'
import KCheckbox from '@sharedComponents/common/k-checkbox.vue'
import KTag from '@sharedComponents/common/k-tag.vue'
import KIcon from '@sharedComponents/common/k-icon.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import moment from 'moment'
import { defaultLocation } from '@vueuse/core/index.cjs'
import PromoterCampaign from '@models/promoter_campaign'
import EventSalesTimeRangeView from '../../common/event-sales-time-range-view.vue'

const store = useStore()

const props = defineProps({
  value: { type: [Array] },
  promoterCampaign: { type: PromoterCampaign },
  timeFormat: { type: String },
})

const data = reactive({
  selDate: null,
  selIds: []
})

const emit = defineEmits(['input'])

const dateFormat = 'YYYY-MM-DD'

const promoterCampaignPeriods = computed(() => {
  return props.promoterCampaign.periods.map((period) => {
    return store.getters['promoterCampaignPeriods/find'](period.id)
  })
})

const registrablePromoterCampaignPeriods = computed(() => {
  return promoterCampaignPeriods.value
    .filter((promoterCampaignPeriod) => {
      return promoterCampaignPeriod.isOnRegistration()
    })
    .sort((a, b) => a.started_at - b.started_at)
})

const eventSalesDates = computed(() => {
  return PromoterCampaign.convertPeriodsToEventDateTimeFormat(
    registrablePromoterCampaignPeriods.value,
    'unix'
  )
})

function selectDateHandler() {
  emit('input', [])
}

const selectableTimes = computed(() => {
  if (!data.selDate) return []

  const dateConfig = eventSalesDates.value.dates.find((dateConfig) => {
    return moment(dateConfig.date).format(dateFormat) === data.selDate
  })

  return dateConfig.times
})

function timeFor(timeConfig) {
  return [
    moment.unix(timeConfig.startedTime).format('HH:mm'),
    moment.unix(timeConfig.endedTime).format('HH:mm')
  ].join(' ~ ')
}

function inputHandler(value) {
  emit('input', value)
}
</script>
