<template lang="pug">
.vc-shared-promoter-campaign-m-campaign-sales-time-range-view-is-main-view
  template(v-if="promoterCampaign.has_periods")
    div(style="display: flex; flex-direction: column;  gap: .5rem;")
      m-campaign-period-sales-time-range-view(
        v-for="(promoterCampaignPeriod, index) in displayPromoterCampaignPeriods"
        :key="promoterCampaignPeriod.id"
        :promoter-campaign-period="promoterCampaignPeriod"
        display-type="oneline"
        include-name
        name-type="is-ci"
        checked
      )
  template(v-else)
    k-datetime-range-view(
      :started-time="promoterCampaign.started_at"
      :ended-time="promoterCampaign.ended_at"
      :display-type="displayType"
      :format="format"
    )
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'skid-composables'
import moment from 'moment'
import KDatetimeRangeView from '@sharedComponents/common/k-datetime-range-view.vue'
import mCampaignPeriodSalesTimeRangeView from '@sharedComponents/promoter_campaign_period/common/m-campaign-period-sales-time-range-view.vue';

const props = defineProps({
  promoterCampaign: { type: Object, required: true },
  selectedCampaignPeriodIds: { type: Array },
  displayType: { type: String, required: false },
  format: {
    type: String,
    required: false,
    default() {
      return 'MM/DD (ddd)'
    }
  }
})
const store = useStore()

const displayPromoterCampaignPeriods = computed(() => {
  if (!props.promoterCampaign.has_periods) return

  if (props.selectedCampaignPeriodIds) {
    return props.selectedCampaignPeriodIds.map((id) => {
      if (!props.promoterCampaign.periods.find(p => p.id === id)) return

      return store.getters['promoterCampaignPeriods/find'](id)
    }).filter(Boolean)
  } else {
    // Default all if props.selectedCampaignPeriodIds is not set
    return props.promoterCampaign.periods.map((period) => {
      return store.getters['promoterCampaignPeriods/find'](period.id)
    })
  }
})
</script>
