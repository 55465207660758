<template lang="pug">
.vc-k-scan-input
  .video-wrapper
    video#video(
      width="300px"
      height="300px"
      autoplay
      playsinline
    )
    canvas#canvas(style="display: none")
  .result.is-flex(v-if="value")
    .unit-label.mr-2 結果:
    .value {{ value }}
  .error.is-flex(v-if="data.error")
    .unit-label.mr-2 錯誤:
    .value {{ data.error }}
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, reactive } from 'vue'
import jsQR from 'jsqr'

const props = defineProps({
  value: { type: String }
})
const emit = defineEmits(['input'])

let video = null
let canvas = null
let context = null

const stream = ref(null)
const data = reactive({
  isScanning: false,
  error: ''
})

onMounted(() => {
  video = document.getElementById('video')
  canvas = document.getElementById('canvas')
  context = canvas.getContext('2d')

  startScanner()
})

onUnmounted(() => {
  stopScanner()
})

function stopScanner() {
  if (!stream.value) return

  stream.value.getTracks().forEach((track) => track.stop())
  stream.value = null
}

async function startScanner() {
  try {
    stream.value = await navigator.mediaDevices.getUserMedia({
      video: { facingMode: 'environment' }
    })

    video.srcObject = stream.value
    const context = canvas.getContext('2d')

    // Wait for video metadata to load
    video.onloadedmetadata = () => {
      video.play()
      canvas.width = video.videoWidth
      canvas.height = video.videoHeight
      data.isScanning = true
      scanQRCode(video, canvas, context)
    }
  } catch (error) {
    data.error = `Error accessing camera: ${error.message}`
  }
}

function scanQRCode(video, canvas, context) {
  if (!data.isScanning) return

  context.drawImage(video, 0, 0, canvas.width, canvas.height)
  const imageData = context.getImageData(0, 0, canvas.width, canvas.height)
  const code = jsQR(imageData.data, imageData.width, imageData.height)

  if (code) {
    emit('input', code.data)
  }

  // Continue scanning
  requestAnimationFrame(() => scanQRCode(video, canvas, context))
}
</script>

<style lang="sass">
.vc-k-scan-input
  .video-wrapper
    display: flex
    justify-content: center
</style>
