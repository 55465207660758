<template lang="pug">
.vc-k-qr-code-view
  .qr-code
  img.image(
    v-if="logoUrl"
    :src="logoUrl"
  )
</template>

<script setup lang="ts">
import { onMounted } from 'vue'
import QrCreator from 'qr-creator'

const props = defineProps({
  url: { type: String, required: true },
  width: { type: Number, default: 200 },
  height: { type: Number, default: 200 },
  logoUrl: { type: String }
})

const renderQrCode = () => {
  QrCreator.render(
    {
      text: props.url,
      radius: 0,
      background: 'white',
      ecLevel: "H",
      width: props.width,
      height: props.height
    },
    document.querySelector('.qr-code')
  )
}

onMounted(async () => {
  renderQrCode()
})
</script>

<style lang="sass">
@use '@shared/css/utils'
@use '@shared/css/abstracts/color.sass'
@use '@shared/css/abstracts/size.sass'

.vc-k-qr-code-view
  position: relative
  .qr-code
    position: relative
    z-index: 10
  .image
    position: absolute
    left: 50%
    top: 50%
    transform: translate(-50%, -50%)
    width: 50px
    height: 50px
    border-radius: 50%
    border: 2px solid color.$white
    z-index: 20
</style>
