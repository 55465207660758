<template lang="pug">
.vc-shared-promoter-campaign-m-campaign-sales-time-range-view-is-main-view
  .wrapper(
    v-for="(dateConfig, index) in displayDates"
    :key="index"
  )
    k-field.mb-2(
      :skip-label="skipLabel"
      label="活動日期"
      horizontal
    )
      k-tag(type="is-info")
        span {{ moment(dateConfig.date).format('MM-DD (ddd)') }}
    k-field(
      :skip-label="skipLabel"
      :key="index"
      label="時段"
      horizontal
    )
      .wrapper
        div(
          v-for="(timeConfig, timeIndex) in displayTimeFor(dateConfig)"
          :key="timeIndex"
        )
          span {{ moment.unix(timeConfig.startedTime).format('HH:mm') }}
          span ~
          span {{ moment.unix(timeConfig.endedTime).format('HH:mm') }}
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'skid-composables'
import moment from 'moment'
import KField from '@sharedComponents/common/k-field.vue'
import KDatetimeRangeView from '@sharedComponents/common/k-datetime-range-view.vue'
import mCampaignPeriodSalesTimeRangeView from '@sharedComponents/promoter_campaign_period/common/m-campaign-period-sales-time-range-view.vue'
import kTag from '@sharedComponents/common/k-tag.vue'
import PromoterCampaign from '@models/promoter_campaign'

const props = defineProps({
  promoterCampaign: { type: Object, required: true },
  selectedCampaignPeriodIds: { type: Array },
  displayType: { type: String, required: false },
  skipLabel: { type: Boolean },
  format: {
    type: String,
    required: false,
    default() {
      return 'MM/DD (ddd)'
    }
  }
})
const store = useStore()

const promoterCampaignPeriods = computed(() => {
  return props.promoterCampaign.periods.map((period) => {
    return store.getters['promoterCampaignPeriods/find'](period.id)
  })
})

const eventSalesDates = computed(() => {
  return PromoterCampaign.convertPeriodsToEventDateTimeFormat(
    promoterCampaignPeriods.value,
    'unix'
  )
})

const displayDates = computed(() => {
  if (props.selectedCampaignPeriodIds) {
    return eventSalesDates.value.dates.filter((dateConfig) => {
      return dateConfig.times.find((timeConfig) => {
        return !!props.selectedCampaignPeriodIds.find(
          (id) => parseInt(id) === parseInt(timeConfig.id)
        )
      })
    })
  } else {
    // Default all if props.selectedCampaignPeriodIds is not set
    return eventSalesDates.value.dates
  }
})

const displayTimeFor = (dateConfig) => {
  if (props.selectedCampaignPeriodIds) {
    return dateConfig.times.filter((timeConfig) => {
      return !!props.selectedCampaignPeriodIds.find(
        (id) => parseInt(id) === parseInt(timeConfig.id)
      )
    })
  } else {
    // Default all if props.selectedCampaignPeriodIds is not set
    return dateConfig.times
  }
}
</script>
