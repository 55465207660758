<template lang="pug">
k-form-container.vc-kolcenter-promoter-campaign-ticket-view(
  style-type="kolcenter-modal-box"
)
  template(#header)
    .title 掃描票卷
  template(#body)
    .main-wrapper
      template(v-if="data.mode === 'scanner'")
        scanner-form(
          :promoter-campaign-share-ship="promoterCampaignShareShip"
          @success="data.isSuccess = true"
        )
      template(v-else-if="data.mode === 'qr_code'")
        qr-code-form(:promoter-campaign-share-ship="promoterCampaignShareShip")
    .button-wrapper(v-if="!data.isSuccess")
      template(v-if="data.mode === 'scanner'")
        k-button(
          type="is-default"
          @click="data.mode = 'qr_code'"
        )
          k-icon(icon="fa-qrcode")
          span 顯示 QR Code
      template(v-else-if="data.mode === 'qr_code'")
        k-button(
          type="is-default"
          @click="data.mode = 'scanner'"
        )
          k-icon(icon="fa-camera")
          span 或用相機掃描票卷
</template>

<script setup lang="ts">
import { computed, onMounted, ref, reactive } from 'vue'
import PromoterCampaignShareShip from '@models/promoter_campaign_share_ship'
import scannerForm from './ticket_view/scanner-form.vue'
import kFormContainer from '@sharedComponents/common/k-form-container.vue'
import qrCodeForm from './ticket_view/qr-code-form.vue'
import kIcon from '@sharedComponents/common/k-icon.vue'
import KButton from '@sharedComponents/common/k-button.vue'

const props = defineProps({
  promoterCampaignShareShip: { type: PromoterCampaignShareShip, required: true }
})

const data = reactive({
  mode: 'qr_code',
  isSuccess: null
})
</script>

<style lang="sass" scoped>
@use '@shared/css/abstracts/color.sass'

.vc-kolcenter-promoter-campaign-ticket-view
  .main-wrapper
    min-height: 300px
  .button-wrapper
    display: flex
    justify-content: center
    margin-top: 2rem
</style>
