<template lang="pug">
.vc-shared-promoter-campaign-form-control-m-campaign-period-selection-input-by-period
  .wrapper(
    v-for="(promoterCampaignPeriod, index) in promoterCampaignPeriods"
    :key="promoterCampaignPeriod.id"
  )
    .checkbox-wrapper
      k-checkbox(
        :value="value"
        :native-value="promoterCampaignPeriod.id"
        @input="inputHandler"
      )
    .name
      span {{ promoterCampaignPeriod.name }}

    k-datetime-range-view.time-range-view(
      :started-time="promoterCampaignPeriod.started_at"
      :ended-time="promoterCampaignPeriod.ended_at"
      display-type="oneline"
      :format="timeFormat"
    )
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'skid-composables'
import KDatetimeRangeView from '@sharedComponents/common/k-datetime-range-view.vue'
import kCheckbox from '@sharedComponents/common/k-checkbox.vue'
import KIcon from '@sharedComponents/common/k-icon.vue'
import PromoterCampaign from '@models/promoter_campaign'

const props = defineProps({
  value: { type: [Array] },
  promoterCampaign: { type: PromoterCampaign },
  timeFormat: { type: String },
})

const emit = defineEmits(['input'])
const store = useStore()

const inputHandler = (value) => {
  emit('input', value)
}

const promoterCampaignPeriods = computed(() => {
  return props.promoterCampaign.periods.map((period) => {
    return store.getters['promoterCampaignPeriods/find'](period.id)
  })
})

const registrablePromoterCampaignPeriods = computed(() => {
  return promoterCampaignPeriods.value
    .filter((promoterCampaignPeriod) => {
      return promoterCampaignPeriod.isOnRegistration()
    })
    .sort((a, b) => a.started_at - b.started_at)
})

const timeFormat = computed(() => {
  return props.timeFormat || props.promoterCampaign.defaultTimeFormat()
})
</script>

<style scoped lang="sass">
@use '@shared/css/abstracts/color.sass'

.vc-shared-promoter-campaign-form-control-m-campaign-period-selection-input-by-period
  display: flex
  flex-direction: column
  gap: .5rem
  .wrapper
    display: flex
    align-items: center
    gap: .5rem
    .time-range-view
      flex-shrink: 0
    .name
      text-align: center
      width: 40%
      padding: .5rem
      border-radius: .75rem
      background-color: color.$ci
      color: color.$white
      box-shadow: 0 1px 0.5rem 0 hsla(0, 0%, 0%, 0.1)
</style>
