<template lang="pug">
.vc-shared-promoter-campaign-period-m-campaign-period-sales-time-range-view(
  :class="{ 'is-ci': nameType === 'is-ci' }"
)
  .selected(v-if="checked")
    .icon
      i.fa.fa-check
  .name(v-if="includeName")
    span {{ promoterCampaignPeriod.name }}

  k-datetime-range-view.time-range-view(
    :started-time="promoterCampaignPeriod.started_at"
    :ended-time="promoterCampaignPeriod.ended_at"
    :display-type="displayType"
    :format="timeFormat"
  )
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'skid-composables'
import KDatetimeRangeView from '@sharedComponents/common/k-datetime-range-view.vue'
import kCheckbox from '@sharedComponents/common/k-checkbox.vue'
import KIcon from '@sharedComponents/common/k-icon.vue'

const props = defineProps({
  promoterCampaignPeriod: { type: Object, required: true },
  displayType: { type: String },
  format: { type: String },
  includeName: { type: Boolean },
  nameType: { type: String, validator: (value) => ['is-ci'].includes(value) },
  checked: { type: Boolean }
})

const store = useStore()

const promoterCampaign = computed(() => {
  return store.getters['promoterCampaigns/find'](
    props.promoterCampaignPeriod.campaign_id
  )
})

const timeFormat = computed(() => {
  return props.format || promoterCampaign.value.defaultTimeFormat()
})
</script>

<style scoped lang="sass">
@use '@shared/css/abstracts/color.sass'

.vc-shared-promoter-campaign-period-m-campaign-period-sales-time-range-view
  display: flex
  align-items: center
  gap: .5rem
  .time-range-view
    flex-shrink: 0
  &.is-ci
    .name
      text-align: center
      width: 40%
      padding: .5rem
      border-radius: .75rem
      background-color: color.$ci
      color: color.$white
      box-shadow: 0 1px 0.5rem 0 hsla(0, 0%, 0%, 0.1)
</style>
