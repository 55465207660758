<template lang="pug">
span.vc-kolcenter-promoter-campaign-ticket-button(
  v-if="promoterCampaignShareShip.state === 'joined'"
)
  k-button(
    type="ci-border"
    @click="showTicketForm"
  ) 顯示票卷

  b-modal(
    :active.sync="data.isFormActive"
    width="450px"
  )
    ticket-view(:promoter-campaign-share-ship="promoterCampaignShareShip")
</template>

<script setup lang="ts">
import { reactive, computed, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import propValidator from '@adminComponents/mixins/slide/prop_validator_mixin.js'
import ticketView from './ticket-view.vue'
import PromoterShare from '@models/promoter_share'
import PromoterCampaign from '@models/promoter_campaign'
import kButton from '@sharedComponents/common/k-button.vue'

const props = defineProps({
  promoterShare: { type: Object, required: true },
  promoterCampaign: { type: Object, required: true }
})

const store = useStore()
const data = reactive({
  isFormActive: false
})

const promoterCampaignShareShip = computed(() => {
  return store.getters['promoterCampaignShareShips/findBy']({
    share_id: parseInt(props.promoterShare.id),
    campaign_id: parseInt(props.promoterCampaign.id)
  })
})

function showTicketForm() {
  data.isFormActive = true
}
</script>
