<template lang="pug">
.vc-shared-promoter-campaign-event-sales-time-range-view
  div.mb-1(
    v-for="(dateConfig, index) in eventSalesDates.dates"
    :key="index"
  )
    span.mr-2 {{ moment(dateConfig.date).format(format) }}
    k-tag.is-light(
      type="is-info"
    )
      span.mr-1 {{ dateConfig.times.length }}
      span 個時段
</template>

<script setup>
import { computed } from 'vue'
import { useStore } from 'skid-composables'
import moment from 'moment'
import KDatetimeRangeView from '@sharedComponents/common/k-datetime-range-view.vue'
import kTag from '@sharedComponents/common/k-tag.vue'
import PromoterCampaign from '@models/promoter_campaign'

const props = defineProps({
  promoterCampaign: { type: Object, required: true },
  displayType: { type: String },
  format: {
    type: String,
    default() {
      return 'MM/DD (ddd)'
    }
  }
})

const store = useStore()

const periods = computed(() => {
  return props.promoterCampaign.periods.map((period) => {
    return store.getters['promoterCampaignPeriods/find'](period.id)
  })
})

const eventSalesDates = computed(() => {
  return PromoterCampaign.convertPeriodsToEventDateTimeFormat(
    periods.value,
    'unix'
  )
})
</script>
