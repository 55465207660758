<template lang="pug">
k-editor-view.vc-shared-promoter-agreement-common-m-default-agreement-view(
  style-type="agreement"
)
  .h1 合作推廣協議內容：

  ol.layer-1
    li.h2 報名細節說明
    ul.layer-2
      li.info 報名後需通過品牌方的審核。通過審核者視同正式進入開團階段，將會收到樣品進行體驗。

    li.h2 推廣要求說明
    ul.layer-2
      li.info 在您報名的檔期活動期間，每日都必須在IG限時動態中進行實拍推廣，並附上專屬賣場連結。限時動態是此次活動的主要推廣方式，且是確保您獲得分潤的關鍵步驟。其即時性和互動性對於品牌推廣效果至關重要，因此每日實拍限時動態的推廣必不可少。文章或Reels則可視個人情況自行選擇是否增加。

    li.h2 推廣未落實處理機制說明
    ul.layer-2
      li.info 若在上述期間有任何一天未在IG限時動態中進行曝光和推廣，系統將自動記錄，廠商會收到即時更新的通知，因此請務必落實合作規範！未完成每日限時動態分享實拍的KOL，將需要自行承擔樣品費用。此外，如收到樣品但未確實拍照使用於推廣，也需要自購樣品。

    li.h2 其他重要資訊
    ul.layer-2
      li.info
        div Ｑ：是否有表單與折扣碼？
        div Ａ：KOLNET系統會於開團期間提供專屬賣場網址，該網址即等同於折扣網址，透過該網址下單即享受折扣，因此不會額外提供折扣碼。您只需分享這個專屬賣場網址即可。

      li.info
        div Ｑ：分潤獎金具體計算方式？
        div Ａ：分潤獎金將根據您銷售的總營業額(不含運)進行計算，不同檔期的分潤比例不同，具體比例％數會在邀約您報名時確認。

      li.info
        div Ｑ：官方LINE作用？
        div Ａ：加入官方LINE後，您將獲得專屬一對一客服服務。在檔期期間，我們將提供文宣素材、每日發文主題建議、消費者行為觀察，以及不定期的爆品活動資訊，協助您輕鬆完成推廣任務。

      li.info
        div Ｑ：如何獲取即時訂單狀況，得知自己的營業額？
        div Ａ：註冊後會獲得專屬後台網址，登入後即可隨時查看最新銷售數據、分潤收益金額、修正個人資訊、優化賣場介面。
</template>

<script setup>
import { computed } from 'vue'
import { useStore, useSwiper } from 'skid-composables'
import kEditorView from '@sharedComponents/common/k-editor-view.vue'

const store = useStore()

</script>
