<template lang="pug">
.vc-kolcenter-promoter-campaign-ticket-view-qr-code-form(
  v-if="data.isDataInited"
)
  .qr-code-wrapper
    k-qr-code-view(
      v-if="promoterCampaignPeriodShare.check_in_token"
      :url="promoterCampaignPeriodShare.check_in_token"
      :logo-url="logoUrl"
    )
</template>

<script setup lang="ts">
import { computed, onMounted, ref, reactive } from 'vue'
import { useStore, useToast } from 'skid-composables'
import PromoterCampaignShareShip from '@models/promoter_campaign_share_ship'
import kQrCodeView from '@sharedComponents/common/k-qr-code-view.vue'

const props = defineProps({
  promoterCampaignShareShip: { type: PromoterCampaignShareShip, required: true }
})

const store = useStore()

const data = reactive({
  isDataInited: false
})

const promoterCampaignPeriodShare = computed(() => {
  return props.promoterCampaignShareShip.campaign_period_share_ids.map((id) => {
    return store.getters['promoterCampaignPeriodShares/find'](id)
  })[0]
})

const promoterShare = computed(() => {
  return store.getters['promoterShares/find'](
    props.promoterCampaignShareShip.share_id
  )
})

const promoterMember = computed(() => {
  return promoterShare.value.getPromoterMember(store)
})

const logoUrl = computed(() => {
  return promoterMember.value.photo?.url
})

async function getnerateCheckInToken() {
  const response = await store.dispatch(
    'promoterCampaignShareShips/generateCheckInToken',
    {
      model: props.promoterCampaignShareShip,
      promoter_campaign_period_share_id:
        props.promoterCampaignShareShip.campaign_period_share_ids[0]
    }
  )
}

onMounted(async () => {
  await getnerateCheckInToken()

  data.isDataInited = true
})
</script>

<style lang="sass" scoped>
@use '@shared/css/utils'
@use '@shared/css/abstracts/size.sass'
@use '@shared/css/abstracts/color.sass'

.vc-kolcenter-promoter-campaign-ticket-view-qr-code-form
  min-height: 200px
  background: color.$white
  padding: 1rem
  display: flex
  align-items: center
  justify-content: center
</style>
