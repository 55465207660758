<template lang="pug">
.vc-kolcenter-promoter-campaign-check-in-scanner-form-scanner-form
  k-field(
    v-if="!data.isSuccess"
    label="請實體活動主辦方的QR Code"
  )
    k-scan-input(
      v-model="data.token"
      @input="scanHandler"
    )

  template(v-if="data.isSuccess")
    k-center-message
      .title(style="font-size: 2rem; margin-bottom: 1rem") 會員報到成功
      .mb-4
        k-icon.has-text-success(
          style="font-size: 2rem"
          icon="fa-check-circle"
        )
      div(style="font-size: 1.5rem") {{ promoterMember.name }}
</template>

<script setup lang="ts"">
import { reactive, computed, onMounted } from 'vue'
import { useStore, useInstance, useToast } from 'skid-composables'
import KFormContainer from '@sharedComponents/common/k-form-container.vue'
import KField from '@sharedComponents/common/k-field.vue'
import KButton from '@sharedComponents/common/k-button.vue'
import KIcon from '@sharedComponents/common/k-icon.vue'
import KScanInput from '@sharedComponents/common/k-scan-input.vue'
import kCenterMessage from '@sharedComponents/common/k-center-message.vue'
import moment from 'moment'
import PromoterCampaignShareShip from '@models/promoter_campaign_share_ship'

const props = defineProps<{
  promoterCampaignShareShip: Object
}>()

const emit = defineEmits(['success'])

const store = useStore()

const data = reactive({
  isLoading: false,
  isSuccess: false,
  token: ''
})

const promterShare = computed(() => {
  if (!props.promoterCampaignShareShip) return null

  return store.getters['promoterShares/find'](
    props.promoterCampaignShareShip.share_id
  )
})

const promoterMember = computed(() => {
  if (!promterShare.value) return null

  return promterShare.value.getPromoterMember(store)
})

async function scanHandler() {
  console.log('scanhandler', data.token)
  if (data.isLoading || data.isSuccess) return

  data.isLoading = true

  try {
    console.log('dispatch')
    const response = await store.dispatch('promoterMembers/performCheckIn', {
      model: promoterMember.value,
      form: {
        host_check_in_token: data.token
      }
    })

    data.token = ''
    data.isSuccess = true
    data.campaignShareShipId = response.data.data.id

    emit('success')
  } catch (e) {
    console.log('erro', e)
    data.token = ''
  } finally {
    data.isLoading = false
  }
}
</script>

<style lang="sass"></style>
