<template lang="pug">
//- router for with periods and without periods
.vc-shared-campaign-overall-time-with-period-range-view
  //- registration times
  .period-unit(
    v-if="promoterCampaign.category === 'main'"
    :class="{ 'has-multiple': registrablePromoterCampaignPeriods.length > 1 }"
  )
    k-icon.register-time-icon(icon="mdi-calendar")
    label.mr-2 {{ attributeLocaleText('promoter/campaign', 'registration_periods') }}
    .value
      template(v-if="!promoterCampaign.has_periods")
        registration-time-range-view(
          :promoter-campaign="promoterCampaign"
          display-type="oneline"
          :format="format"
        )
      template(v-else)
        template(v-if="promoterCampaign.isOnRegistration()")
          period-registration-time-range-view(
            v-for="(promoterCampaignPeriod, index) in registrablePromoterCampaignPeriods"
            :key="index"
            :promoter-campaign-period="promoterCampaignPeriod"
            display-type="oneline"
            :format="format"
          )
        template(v-else)
          registration-time-range-view(
            :promoter-campaign="promoterCampaign"
            display-type="oneline"
            :format="format"
        )

  // sales times
  .period-unit(
    :class="{ 'has-multiple': registrablePromoterCampaignPeriods.length > 1 }"
  )
    k-icon.sales-time-icon(icon="mdi-calendar")
    label.period-label.mr-2
      span(v-if="promoterCampaign.category === 'event'") {{ attributeLocaleText('promoter/campaign', 'event_periods') }}
      span(v-else) {{ attributeLocaleText('promoter/campaign', 'sales_periods') }}
    .value
      template(v-if="promoterCampaign.category === 'event'")
        event-sales-time-range-view(
          :promoter-campaign="promoterCampaign"
          :display-type="displayType"
          :format="format"
        )
      template(v-else)
        template(v-if="!promoterCampaign.has_periods")
          sales-time-range-view(
            :promoter-campaign="promoterCampaign"
            :display-type="displayType"
            :format="format"
          )
        template(v-else)
          template(v-if="promoterCampaign.isOnRegistration()")
            period-sales-time-range-view(
              v-for="(promoterCampaignPeriod, index) in registrablePromoterCampaignPeriods"
              :key="index"
              :promoter-campaign-period="promoterCampaignPeriod"
              :display-type="displayType"
              :format="format"
            )
          template(v-else)
            sales-time-range-view(
              :promoter-campaign="promoterCampaign"
              :display-type="displayType"
              :format="format"
            )

  .address(
    v-if="promoterCampaign.category === 'event' && promoterCampaign.event_info.full_address"
  )
    .wrapper(
      style="display: flex; gap: .25rem; align-items: baseline"
    )
      k-icon(icon="mdi-map-marker")
      .value
        span 活動地點：
        span {{ promoterCampaign.event_info.full_address }}

  .event-info-fee(
    v-if="promoterCampaign.category === 'event' && promoterCampaign.match_rule_data.fixed_fee && showEventInfoFee"
  )
    .wrapper(
      style="display: flex; gap: .25rem; align-items: center"
    )
      k-icon(icon="fa-dollar")
      .value
        span 預計報酬：
        span {{ promoterCampaign.match_rule_data.fixed_fee }}
        span 元起
</template>

<script setup>
import { computed, reactive, onMounted } from 'vue'
import { useStore } from 'skid-composables'
import moment from 'moment'
import RegistrationTimeRangeView from '@sharedComponents/promoter_campaign/common/registration-time-range-view.vue'
import PeriodRegistrationTimeRangeView from '@sharedComponents/promoter_campaign_period/common/registration-time-range-view.vue'
import SalesTimeRangeView from '@sharedComponents/promoter_campaign/common/sales-time-range-view.vue'
import eventSalesTimeRangeView from './event-sales-time-range-view.vue'
import PeriodSalesTimeRangeView from '@sharedComponents/promoter_campaign_period/common/sales-time-range-view.vue'
import KIcon from '@sharedComponents/common/k-icon.vue'

const props = defineProps({
  promoterCampaign: { type: Object, required: true },
  selPromoterCampaignPeriod: { type: Object },
  displayType: { type: String },
  format: { type: String },
  showEventInfoFee: { type: Boolean }
})

const store = useStore()

const data = reactive({
  promoterCampaignPeriodIds: []
})

const promoterCampaignPeriods = computed(() => {
  return data.promoterCampaignPeriodIds.map((id) => {
    return store.getters['promoterCampaignPeriods/find'](id)
  })
})

const registrablePromoterCampaignPeriods = computed(() => {
  if (props.selPromoterCampaignPeriod) return [props.selPromoterCampaignPeriod]

  return promoterCampaignPeriods.value.filter((promoterCampaignPeriod) => {
    return promoterCampaignPeriod.isOnRegistration()
  })
})

const fetchPeriods = async () => {
  const response = await store.dispatch('promoterCampaigns/fetchPeriods', {
    model: props.promoterCampaign
  })

  data.promoterCampaignPeriodIds = response.data.data.map((row) => row.id)
}

onMounted(() => {
  if (
    props.promoterCampaign.has_periods &&
    promoterCampaignPeriods.value.length === 0
  )
    fetchPeriods()
})
</script>

<style lang="sass">
@use '@shared/css/abstracts/color.sass'

.vc-shared-campaign-overall-time-with-period-range-view
  .register-time-icon
    color: color.$ci
    height: auto
    font-size: 14px
    line-height: 1
  .sales-time-icon
    color: color.$deep-pink
    height: auto
    font-size: 14px
    line-height: 1
  .period-unit
    display: flex
    align-items: center
    > *
      flex-shrink: 0
    &.has-multiple
      align-items: baseline
  .address
    display: flex
    align-items: center
</style>
