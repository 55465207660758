<template lang="pug">
.vc-shared-promoter-campaign-form-control-m-campaign-period-selection-input
  div(
    :is="variantComponent"
    :value="value"
    :promoter-campaign="promoterCampaign"
    @input="inputHandler"
  )
</template>

<script setup lang="ts">
import { reactive, computed, onMounted, watch } from 'vue'
import PromtoerCamapign from '@models/promoter_campaign'
import ByPeriod from './m_campaign_period_selection_input/by-period.vue'
import ByDateTime from './m_campaign_period_selection_input/by-date-time.vue'

const props = defineProps<{
  value: (string | number)[];
  promoterCampaign: PromtoerCamapign;
}>()

const emit = defineEmits(['input'])

const variantComponent = computed(() => {
  switch (props.promoterCampaign.category) {
    case 'event':
      return ByDateTime
    default:
      return ByPeriod
  }
})

function inputHandler(value) {
  emit('input', value)
}
</script>
