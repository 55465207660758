<template lang="pug">
.vc-shared-promoter-agreement-common-m-agreement-view
  template(v-if="promoterAgreement.isNewRecord()")
    m-default-agreement-view
  template(v-else)
    k-editor-view(
      style-type="agreement"
    )
      .h1 {{ promoterAgreement.title }}
      div(
        v-html="promoterAgreement.content"
      )
</template>

<script setup>
import { computed } from 'vue'
import { useStore, useSwiper } from 'skid-composables'
import kEditorView from '@sharedComponents/common/k-editor-view.vue'
import mDefaultAgreementView from '@sharedComponents/promoter_agreement/common/m-default-agreement-view.vue'
import PromoterAgreement from '@models/promoter_agreement'

const store = useStore()
const props = defineProps({
  promoterAgreement: { type: PromoterAgreement, required: true }
})
</script>
