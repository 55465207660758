import ResourceModelBase from 'odd-resource_model'

const OPTIONS = {
  apiPath: '/api',
  apiVersion: 'v1',
  scope: 'web',
  resourceType: 'promoter_agreements',
  attributes: [
    'id',
    'title',
    'content',
    'default_category',
    'created_at',
    'updated_at'
  ],
  editableAttributes: ['title', 'content', 'default_category']
}

const DEFAULT_CATEGORIES = [
  'official',
  'kol-select',
  'main',
  'buyout',
  'event',
  'sponsored'
]

export default class PromoterAgreement extends ResourceModelBase {
  constructor(attributes = {}) {
    super(OPTIONS, attributes)
  }

  static defaultCategories() {
    return DEFAULT_CATEGORIES
  }
}
